<template>
  <v-row class="match-height">
    <v-col cols="12">
      <app-card-code :title="`${$t('menu.configuration')} / ${$t('menu.notifications')} / DNS - Email`">
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="error"
                class="mb-4 me-3"
                @click="$router.push({ name: 'dns' })"
              >
                <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
                <span>{{ $t('btn.back') }}</span>
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="primary"
                class="mb-4 me-3"
                :loading="loading"
                @click="save()"
              >
                <span>{{ btnTitle }}</span>
                <v-icon>{{ icons.mdiUpdate }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-form
            v-if="!isLoading"
            class="multi-col-validation"
          >
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-row class="pb-5">
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-text-field
                      v-model="model.identificador"
                      :disabled="onlyShow"
                      :label="$t('lbl.identificador')"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-row>
                  <!--<v-col
                    cols="12"
                    md="2"
                  >
                    <v-text-field
                      v-model="model.scheme"
                      :disabled="onlyShow"
                      label="MAILER"
                      placeholder="smpt"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>-->
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-select
                      v-model="model.encryption"
                      :items="encryptions"
                      :disabled="onlyShow"
                      label="Método de cifrado"
                      outlined
                      dense
                      hide-details="auto"
                      item-text="name"
                      item-value="slug"
                    ></v-select>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-text-field
                      v-model="model.host"
                      :disabled="onlyShow"
                      label="Servidor de correo saliente"
                      placeholder="smtp.server.pt"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <!--<v-text-field
                      v-model="model.port"
                      :disabled="onlyShow"
                      label="Puerto"
                      placeholder="587"
                      outlined
                      dense
                      hide-details
                      type="number"
                    ></v-text-field>-->
                    <v-select
                      v-model="model.port"
                      :items="ports"
                      :disabled="onlyShow"
                      label="Puerto"
                      outlined
                      dense
                      hide-details="auto"
                      item-text="name"
                      item-value="slug"
                    ></v-select>
                  </v-col>
                </v-row>

                <v-row>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-text-field
                      v-model="model.from_name"
                      :disabled="onlyShow"
                      label="Nombre del Destinatario"
                      placeholder="Info"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-text-field
                      v-model="model.user"
                      :disabled="onlyShow"
                      label="Username"
                      placeholder="no-reply@server.pt"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-text-field
                      v-model="model.password"
                      :disabled="onlyShow"
                      label="Contraseña"
                      type="password"
                      placeholder="**********"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <h3>{{ $t('lbl.notyBy') }}:</h3>
              </v-col>
              <v-col
                v-for="(module, i) in modules"
                :key="i"
                cols="12"
                md="6"
              >
                <v-checkbox
                  v-model="modulesCheck"
                  hide-details
                  class="mt-1"
                  :value="module.slug"
                  :disabled="module.disabled"
                >
                  <template #label>
                    <span>{{ module.name }}</span>
                  </template>
                </v-checkbox>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </app-card-code>
    </v-col>
    <Loading
      v-if="loading"
      :full-page="true"
      :color="$vuetify.theme.themes.light.primary"
    />
  </v-row>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
// eslint-disable-next-line import/no-unresolved
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiTrashCan,
  mdiAirplaneTakeoff,
  mdiCity,
  mdiMapPlus,
  mdiMapMinus,
} from '@mdi/js'
// eslint-disable-next-line import/no-unresolved
import Loading from '@/views/utils/loading/bars.vue'

export default {
  components: {
    AppCardCode,
    Loading,
  },
  data() {
    return {
      isLoading: true,
      editGeoTag: true,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiTrashCan,
        mdiAirplaneTakeoff,
        mdiCity,
        mdiMapPlus,
        mdiMapMinus,
      },
      model: {},
      showOptions: false,
      loading: false,
      encryptions: [],
      ports: [],
      modules: [],
      modulesCheck: [],
      modelesBusy: [],
    }
  },
  computed: {
    btnTitle() {
      return localStorage.getItem('dns-id') === null ? this.$t('btn.send') : this.$t('btn.update')
    },
    ...mapState({
      optionsChatBotMessages: state => state.app.optionsChatBotMessages,
      onlyShow: state => state.app.onlyShow,
    }),
  },
  created() {
    this.encryptions.push({
      slug: 'tls',
      name: 'tls',
    })
    this.encryptions.push({
      slug: 'ssl',
      name: 'ssl',
    })
    this.ports.push({
      slug: 25,
      name: 25,
    })
    this.ports.push({
      slug: 465,
      name: 465,
    })
    this.ports.push({
      slug: 587,
      name: 587,
    })
    this.modules.push({
      slug: 'update-users',
      name: 'Usuarios / Creación y Activación',
      disabled: false,
    })
    this.modules.push({
      slug: 'update-apis',
      name: 'APIs / Creación, Activación y Desactivación',
      disabled: false,
    })
    this.modules.push({
      slug: 'cotizaciones',
      name: 'Cotizaciones',
      disabled: false,
    })
    this.modules.push({
      slug: 'reservas',
      name: 'Reservas',
      disabled: false,
    })
    this.modules.push({
      slug: 'facturas-voucher',
      name: 'Facturas y Vouchers / Envío de PDF',
      disabled: false,
    })
    this.modules.push({
      slug: 'channel-manager',
      name: 'Channel Manager',
      disabled: false,
    })
    this.modules.push({
      slug: 'update-company',
      name: 'Compañías / Creación y Eliminación',
    })
    this.modules.push({
      slug: 'update-contrates',
      name: 'Contratos / Creación y Eliminación',
    })
    this.modules.push({
      slug: 'newsletter',
      name: 'Newsletter',
      disabled: false,
    })
    this.modules.push({
      slug: 'events',
      name: 'Ferias y eventos',
      disabled: false,
    })

    this.getAllItem()
    this.getItem()
  },
  methods: {
    ...mapMutations(['adicionarOptionsMessaje', 'deleteOptionsMessaje', 'updateOptionsMessaje', 'setOnlyShow']),

    getItem() {
      if (localStorage.getItem('dns-id') !== null) {
        const id = localStorage.getItem('dns-id')
        this.axios
          .get(`dns_email/${id}`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            this.model = res.data.data.data
            this.modulesCheck = this.model.notifications
          })
          // eslint-disable-next-line no-return-assign
          .finally(() => (this.isLoading = false))
      } else {
        this.isLoading = false
      }
    },
    getAllItem() {
      this.axios
        .get('dns_email', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          const all = res.data.data
          let id = 0
          if (localStorage.getItem('dns-id') !== null) {
            id = localStorage.getItem('dns-id')
          }
          const notificationsBusy = []
          all.forEach(element => {
            if (id !== element.id) {
              element.notifications.forEach(elementChild => {
                notificationsBusy.push(elementChild)
              })
            }
          })
          // eslint-disable-next-line no-plusplus
          for (let index = 0; index < this.modules.length; index++) {
            if (notificationsBusy.includes(this.modules[index].slug)) {
              this.modules[index].disabled = true
            }
          }
        })
    },

    save() {
      if (this.model.host
        && this.model.port
        && this.model.user
        && this.model.password
        && this.model.encryption
        && this.model.from_name
        && this.modulesCheck.length > 0) {
        this.loading = true
        const json = this.model

        json.scheme = 'smtp'
        json.notifications = this.modulesCheck

        if (localStorage.getItem('dns-id') === null) {
          this.axios
            .post('dns_email', json, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              if (response.data.success === true) {
                localStorage.removeItem('dns-id')
                this.$router.push({ name: 'dns' })
              } else if (response.data.success === false) {
                if (response.data.data.status === 301) {
                  this.$toast.error('DNS existente.')
                } else if (response.data.data.status === 404) {
                  this.$toast.error('No fue posible la comunicación con el servidor DNS.')
                }
              }
            })
            .catch(error => console.log(error))
            // eslint-disable-next-line no-return-assign
            .finally(() => (this.loading = false))
        } else {
          this.axios
            .put(`dns_email/${this.model.id}`, json, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              if (response.data.success === true) {
                localStorage.removeItem('dns-id')
                this.$router.push({ name: 'dns' })
              } else if (response.data.success === false) {
                if (response.data.data.status === 404) {
                  this.$toast.error('No fue posible la comunicación con el servidor DNS.')
                }
              }
            })
            .catch(error => console.log(error))
            // eslint-disable-next-line no-return-assign
            .finally(() => (this.loading = false))
        }
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
  },
}
</script>
